import React, {useState, useEffect} from 'react'
import {Spin, Empty} from 'antd'
import {get, isEqual} from 'lodash'
import {utils} from 'dtchain-fe'
import {useLocation, useParams, useHistory} from 'react-router-dom'
import Breadcrumb from '../components/Breadcrumb'
import Trace from './trace/trace'
import Integral from './integral/integral'
import Chain from './chain/chain'
import Card from '../components/Card'
import Mask from '../components/Mask'
import Settle from './settle/settle'
import api, {handleResponse} from '../api'

const breadcrumb = [
  {breadcrumbName: '首页', path: '/'},
  {breadcrumbName: '交易详情'}
]

const Detail = () => {
  const location = useLocation()
  const params = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState(null)
  const [trxType, setTrxtype] = useState(null)
  const [data, setData] = useState({})
  const [showMask, setShowMask] = useState(false)

  useEffect(() => {
    if(location.state) {
      const {data} = location.state
      const type = get(data, 'trx_info.biz_type', {})
      setTrxtype(get(data, 'trx_info.trx_type', {}))
      setCode(type.code)
      setData(data)
      if(params['id'] === undefined) {
        setShowMask(true)
      } else {
        setShowMask(false)
      }
    } else if(!location.state && params['id']) {
      (async () => {
        setShowMask(false)
        try {
          setLoading(true)
          const response = await api.getDetail(params.id)
          handleResponse({
            response: response,
            resolve: data => {
              if(data.length === 0) {
                utils.Message('warning', '交易未找到')
              } else {
                const detailData = data[0]
                const type = get(detailData, 'trx_info.biz_type', {})
                setTrxtype(get(detailData, 'trx_info.trx_type', {}))
                setCode(type.code)
                setData(detailData)
              }
              setLoading(false)
            },
            reject: () => setLoading(false)
          })
        } catch (error) {
          setLoading(false)
        }
      })()
    } else {
      setShowMask(false)
      history.replace('/')
    }
    
  }, [history, location.state, params, params.id])


  const getPage = (code, type) => {
    switch (code) {
      case 1001:
        return <Integral data={data}/>
      case 1003:
        return <Trace data={data}/>
      case 1004:
        if(type.code === 3) {
          return <Settle data={data} />
        }
        return <Chain data={data}/>
      default:
        return <Card >{!loading && isEqual(data, {}) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="交易未找到"/>}</Card>
    }
  }

  const hash = get(data, 'trx_info.trx_hash', '')

  return (
    <div className='detail container'>
      <Breadcrumb routes={breadcrumb}/>
      <Spin spinning={loading}>
        {
          showMask ? <Integral data={data}/> : getPage(code, trxType)
        }
        <Mask visible={showMask} compare={hash}/>
      </Spin>
    </div>
  )
}

export default Detail