import React from 'react'
import IntegralCard from '../../components/IntegralCard'
import SettleInfo from '../../components/SettleInfo'
import SettleDetail from '../../components/SettleDetail'

const Integral = ({data={}}) => {
  return (
    <div className='settle'>
      <IntegralCard data={data['trx_info']}/>
      <SettleInfo data={data['reference_data']}/>
      <SettleDetail data={data['reference_data']} />
    </div>
  )
}

export default Integral