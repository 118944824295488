/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux'
import * as types from "../constants/ActionTypes"
import createReducer from  './reducerTemplate/createReducer'
import createReducerWithState from './reducerTemplate/createReducerWithState'

function createFilteredReducer(reducerFunction, type, reducerPredicate = action => action) {
  return (state, action) => {
    const isInitializationCall = state === undefined
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall
    return shouldRunWrappedReducer ? reducerFunction(type, action, state) : state
  }
}

const rootReducer = combineReducers({
  chain: combineReducers({
    assetsRecords: createFilteredReducer(createReducerWithState, types.GET_ASSETS_RECORDS)
  })
})

export default rootReducer
