import React, {Fragment} from 'react'

const Steps = ({data = [], isOne}) => {
  return (
    <div className="steps">
      <div className="steps-line"/>
      <div className="steps-container">
        <div className="steps-item">
          {
            data.map((i, index) => {
              const location = i.location || {}
              return (
                <Fragment key={index}>
                  <div className="steps-title"> 
                    <div className="steps-icon"/>
                    <span className="time">{i.time}</span>
                    {isOne && index === 0 && <div className="new">最新</div>}
                  </div>
                  <div className="steps-content">
                    <div className="item">
                      <div className="icon icon-address" />
                      <div className="content">
                        <span className="label">地理坐标</span>：{i.no || '--'}<span className="dot">（{location.lng || '--'}，{location.lat || '--'}）</span>                
                      </div>
                    </div>
                    <div className="item">
                    <div className="icon icon-recorder" />
                      <div className="content">
                        <span className="label">记录人</span>：{i.operator || '--'}
                      </div>   
                    </div>
                  </div>
                </Fragment>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Steps
