import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as lodash from 'lodash'
import {utils} from 'dtchain-fe'
import {Spin} from 'antd'
import Breadcrumb from '../../components/Breadcrumb'
import Card from '../../components/Card'
import BlockAddress from '../../components/BlockAddress'
import PaymentIcon from '../../styles/images/chain/payer.png'
import PayeeIcon from '../../styles/images/chain/receiver.png'
import TradeIcon from '../../styles/images/chain/trade-detail.png'
import api, {handleResponse} from '../../api'


const TransInfo = () => {
  const params = useParams()
  const [transInfoData, setTransInfoData] = useState({})
  const [loading, setLoading] = useState(false)

  const breadcrumb = [
    {breadcrumbName: '首页', path: '/'},
    {breadcrumbName: '交易详情', path: `/detail/${params['hash']}`},
    {breadcrumbName: '流转信息'},
  ]
  
  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const response = await api.getTransRecords({hash: params['hash'], contractNo: params['contractNo']})
        handleResponse({
          response: response,
          resolve: data => {
            setTransInfoData(data)
            setLoading(false)
          },
          reject: () => setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    })()
  }, [params])

  const paymentInfo = [
    {label: '凭证编号', content: lodash.get(transInfoData, 'data.sender.token_no', '--')},
    {label: '支付面值', content: utils.formatCurrency(lodash.get(transInfoData, 'data.sender.credit'))},
    {label: '持有方', content: lodash.get(transInfoData, 'data.sender.corp_name', '--')},
    {label: '债务人', content: lodash.get(transInfoData, 'data.sender.debtor_name', '--')},
    {label: '转出地址', content: lodash.get(transInfoData, 'data.sender.address', '--')},
  ]

  const payeeInfo = [
    {label: '凭证编号', content: lodash.get(transInfoData, 'data.receiver.token_no', '--')},
    {label: '收款面值', content: utils.formatCurrency(lodash.get(transInfoData, 'data.receiver.credit'))},
    {label: '持有方', content: lodash.get(transInfoData, 'data.receiver.corp_name', '--')},
    {label: '债务人', content: lodash.get(transInfoData, 'data.receiver.debtor_name', '--')},
    {label: '转入地址', content: lodash.get(transInfoData, 'data.receiver.address', '--')},
  ]

  const tradeDetail = [
    {label: '交易创建时间', content: utils.formatTime(lodash.get(transInfoData, 'data.trx_time'))},
    {label: '合同编号', content: lodash.get(transInfoData, 'data.contract_no', '--')},
    {label: '区块地址', content: <BlockAddress hash={lodash.get(transInfoData, 'data.block_hash')} anchorInfo={lodash.get(transInfoData, 'anchor_info')}/>},
    {label: '交易合同', content: <a href={lodash.get(transInfoData, 'data.contract.file_url')} target='_blank' rel="noopener noreferrer">点击查看</a>},
  ]

  return (
    <div className='chain-trans container'>
      <Breadcrumb routes={breadcrumb} />
      <Spin spinning={loading}>
        <div className='chain-trans-info'>
          <Card title='支付方信息' icon={PaymentIcon} content={paymentInfo} className='chain-trans-info-item'/>
          <div className="chain-trans-info-arrow">
            <div className="chain-trans-info-arrow-image"/>
            <div className="chain-trans-info-arrow-text">流转至</div>
          </div>
          <Card title='收款方信息' icon={PayeeIcon} content={payeeInfo}  className='chain-trans-info-item'/>
        </div>
        <div className='chain-trans-detail'>
          <Card title='交易详情' icon={TradeIcon} content={tradeDetail}/>
        </div>
      </Spin>
    </div>
  )
}

export default TransInfo