import React, {Component} from 'react'
import Search from '../../components/Search'
import BlockInfo from "./BlockInfo"
import TradeTable from "./TradeTable"
import NodeTable from "./NodeTable"
import NodeAnimation from "./components/NodeAnimation"
import api from '../../api'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      nodeInfo: [],
      seconds: 0,
      loading: false,
      hasTime: false
    }
    this.getData = this.getData.bind(this)
  }
  countDown() {
    this.t = window.setInterval(() => {
      this.setState({
        seconds: this.state.seconds + 1000
      })
    }, 1000)
  }
  getData() {
    const {hasTime} = this.state
    !hasTime && this.setState({loading: true})
    api.getIndexData().then(res => {
      if(res.data.code === 0) {
        const data = res.data.data || {}
        const nodes = data.nodes || []
        this.setState({data: data, nodeInfo: nodes})
        if(!hasTime) {
          this.setState({seconds : new Date().getTime() - data.bootstrap_time})
          !this.t && this.countDown()
        }
        this.setState({hasTime: true})
      }
      this.setState({loading: false})
    }).catch(() => this.setState({loading: false}))
  }
  
  componentDidMount() {
    this.getData()
    this.refreshData = window.setInterval(() => {
      this.getData()
    }, 2000)
  }

  componentWillUnmount() {
    window.clearInterval(this.t)
    window.clearInterval(this.refreshData)
  }
  formatSecond(ms) {
    let t = ''
    if(ms >= 0){
      // let s = Math.floor(ms/1000)
      let s = Math.floor(ms/1000)
      let day = Math.floor(s/3600/24)
      let hour = Math.floor(s/3600) % 24
      let min = Math.floor(s/60) % 60
      let sec = s % 60

      t = day + ':'
      if(hour < 10) {
        t += '0'+ hour + ":"
      } else {
        t += hour + ":"
      }

      if(min < 10){t += "0"}
      t += min + ":"
      if(sec < 10){t += "0"}
      t += sec
    }
    return t
  }

  makeCredit() {
    let nodeArr = []
    const {data} = this.state
    const creditStr = String(data.total_credit)
    let credit = data.total_credit ? [...creditStr] : Array(12).fill('0')

    if(credit.length < 12) {
      for(let i = 0; i < (12 - creditStr.length); i+=1) {
        credit.unshift('0')
      }
    }

    [...credit].reverse().forEach((i, index)=> {
      nodeArr.push(<span key={index} className="num">{i}</span>);
      ((index+1) % 3 === 0) && nodeArr.push(<span key={","+index} className="mark">,</span>)
    })
    
    if(nodeArr[nodeArr.length - 1].props.children === ',') nodeArr.pop()
    return nodeArr.reverse()
  }

  render() {
    const {data, nodeInfo} = this.state

    let time = this.formatSecond(this.state.seconds).split(":")

    return (
      <div className="home">
        <div className="header">
          <div className="title">DTChain 区块链浏览器</div>
          <div className="description">提供区块、交易、资产等查询功能</div>
          <div className="home-search">
            <Search/>
          </div>
          <div className="statistics">
            <div className="account">
              <div className="info"><span>{data.user_count || 0}</span></div>
              <div className="des">总账户数</div>
            </div>
            <div className="time">
              <div className="info">
                <span>{time[0] || '00'}</span>天<span>{time[1] || '00'}</span>小时<span>{time[2] || '00'}</span>分<span>{time[3] || '00'}</span>秒
              </div>
              <div className="des">运营时间</div>
            </div>
            <div className="trade">
              <div className="info"><span>{data.trx_count || 0}</span></div>
              <div className="des">总交易数</div>
            </div>
          </div>
          <NodeAnimation top={20} left={-400} data={nodeInfo[0]}/>
          <NodeAnimation top={150} left={-450} data={nodeInfo[1]}/>
          <NodeAnimation top={60} left={400} data={nodeInfo[2]}/>
          <NodeAnimation top={250} left={450} data={nodeInfo[3]}/>
        </div>
        <div className="trade-box">
          <div className="trade-body">
            <div className="box-title">总交易量</div>
            <div className="content">
              {this.makeCredit()}
            </div>
          </div>
        </div>
        <div className="container">
          <BlockInfo node={nodeInfo.length} block_height={data.block_height} confirmed_height={data.confirmed_height}/>
          <TradeTable data={data.transactions || []} loading={this.state.loading}/>
          <NodeTable data={nodeInfo} loading={this.state.loading}/>
        </div>
      </div>
    )
  }
}

export default Home