import React, {Fragment} from 'react'

const NotFound = () => {
  return (
    <Fragment>
      <div className="not-found"/>
    </Fragment>
  )
}

export default NotFound