import React, {Fragment} from 'react'
import Card from '../../../components/Card'
import Steps from './Steps'
import IntegralIcon from '../../../styles/images/integral/block.png'

const Transfer = ({data = []}) => {
  return (
    <Card icon={IntegralIcon} title="流通溯源链" className="trace-transfer">
      <div className="transfer">
        {
          data.map((i, index) => (
            <Fragment key={index}>
              <div className="code" data-code={i.code}>
                {index === 0 ? '映物码' : '父级映物码'}：{i.code}
              </div>
              <Steps data={i.paths} isOne={index === 0}/>
            </Fragment>
          ))
        }
      </div>
    </Card>
  )
}

export default Transfer