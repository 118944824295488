import React from 'react'
import {Table} from 'antd'
import {Link} from 'react-router-dom'
import {utils} from 'dtchain-fe'
import Card from '../../../components/Card'
import SettleIcon from '../../../styles/images/chain/settle-record.png'

const columns = hash => [
  {
    title: <span className='column-title'>结算单号</span>,
    dataIndex: 'request_no',
    key: 'request_no',
    align: 'center',
    className: 'table-column',
    render: text => (<Link to={`/detail/settle/${hash}/${text}`}>{text}</Link>)
  },
  {
    title: <span className='column-title'>结算金额</span>,
    dataIndex: 'money',
    key: 'money',
    className: 'table-column',
    align: 'center',
    render: text => utils.formatCurrency(text)
  },
  {
    title: <span className='column-title'>手续费</span>,
    dataIndex: 'fee',
    key: 'fee',
    align: 'center',
    className: 'table-column',
    render: text => utils.formatCurrency(text)
  },
  {
    title: <span className='column-title'>申请结算时间</span>,
    dataIndex: 'request_time',
    key: 'request_time',
    align: 'center',
    className: 'table-column',
    render: text => utils.formatTime(text)
  },
  {
    title: <span className='column-title'>结算完成时间</span>,
    dataIndex: 'complete_time',
    key: 'complete_time',
    align: 'center',
    className: 'table-column',
    render: text => text ? utils.formatTime(text) : '--'
  },
]

const SettleRecords = ({hash, data=[]}) => {

  return (
    <Card title='结算记录' icon={SettleIcon} className='trans-records'>
      <div className='table-container'>
        <Table 
          columns={columns(hash)} 
          dataSource={data} 
          pagination={false}
          bordered={false}
          rowKey='request_no'
          scroll={ data.length > 5 ? { y: 200 } : {}} 
        />
      </div>
    </Card>
  )
}

export default SettleRecords