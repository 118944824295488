/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import IntegralCard from '../../components/IntegralCard'
import ProductInfo from '../../components/ProductInfo'
import TraceMap from './components/TraceMap'
import Transfer from './components/Transfer'

const Trace = ({data = {}}) => {
  const [traces, setTraces] = useState([])
  const [mark, setMark] = useState([])

  const formatTraces = (data = []) => {
    let res = []
    let mark = []

    data.forEach(i => {
      let arr = [{...i, operator: i.owner}]
      let mark_arr = [{
        latitude: i.location.lat,
        longitude: i.location.lng,
        title: i.no
      }]

      i.paths && i.paths.forEach(n => {
        arr.push(n)
        mark_arr.push({
          latitude: n.location.lat,
          longitude: n.location.lng,
          title: n.no
        })
      })
      res.push({...i, paths: arr})
      mark = mark.concat(mark_arr)
    })
    setTraces(res)
    setMark(mark)
  }

  useEffect(() => {
    formatTraces(data.traces)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  return (
    <div className='trace'>
      <IntegralCard data={data.trx_info}/>
      <ProductInfo data={data.item_info}/>
      <TraceMap mark={mark}/>
      <Transfer data={traces}/>
    </div>
  )
}

export default withRouter(Trace)