import React, {useEffect} from 'react'
import {Route, Switch, useLocation} from 'react-router-dom'
import Home from './pages/home/home'
import Detail from './pages/detail'
import ChainRouter from './pages/chain/router'
import TraceRouter from './pages/trace/router'
import NotFound from './pages/not_found'
import Header from './components/Header'
import Footer from './components/Footer'

function ScrollToTop({...props}) {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return props.children
}

const App = ({location}) => {
  return [
    <Header key='header' search={location.pathname !== '/'}/>,
    <ScrollToTop key="router">
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/detail/:id?' component={Detail} />
        <Route path='/detail' component={ChainRouter} />
        <Route path='/trace' component={TraceRouter} />
        <Route component={NotFound}/>
      </Switch>
    </ScrollToTop>,
    <Footer key='footer' />
  ]
}

export default App
