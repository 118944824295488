import React from 'react'
import Card from './Card'
import {utils} from 'dtchain-fe'
import icon from '../styles/images/trace/detail.png'
import TransStatus from './TransStatus'
import BlockAddress from '../components/BlockAddress'
import {get} from 'lodash'

const IntegralCard = ({data = {}}) => {
  const content=[
    {label: '交易哈希', content: <BlockAddress hash={data.trx_hash} anchorInfo={data['anchor']}/>},
    {label: '交易量', content: data.credit},
    {label: '交易时间', content: utils.formatTime(data.trx_time)},
    {label: '区块确认时间', content: utils.formatTime(data.confirm_time)},
    {label: '所在区块高度', content: data.block_height},
    {label: '发送方', content: data.from_address},
    {label: '接收方', content: data.to_address},
    {label: '交易状态', content: <TransStatus status={get(data, 'status.code')} />},
  ]

  return (
    <Card icon={icon} title='交易详情' content={content}/>
  )
}

export default IntegralCard