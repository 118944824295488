import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Trace from './trace'
import NotFound from '../not_found'

export default () => {
  return (
    <Switch>
      <Route exact path="/trace/:id?" component={Trace}/>
      <Route component={NotFound}/>
    </Switch>
  )
}
