import React, { Component } from 'react'
import PropTypes from 'prop-types'
import icon_location from '../styles/images/trace/icon_location.png'

let qq = window.qq = window.qq || {}
let QMap = window.qq.maps || null
qq.maps = qq.maps || {}

const INITIALOPTIONS = {
  zoom: 5,
  disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
}

const checkCenter = center => center && center.latitude && center.longitude

class ReactQMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      markers: []
    }
  }
  componentDidMount() {
    if (!QMap) this._addScript()
    else checkCenter(this.props.center) && this._initMap(this.props)  // center存在时执行初始化
  }
  componentDidUpdate(prevProps) {
    const {center, mark} = this.props
    if (center && JSON.stringify(prevProps.center) !== JSON.stringify(center)) {
      if (!checkCenter(center)) {
        this._initMap(this.props)   // center默认不存在时执行初始化
      } else {
        this.map.setCenter(new QMap.LatLng(center.latitude, center.longitude))
      }
    }
    if (mark && JSON.stringify(prevProps.mark) !== JSON.stringify(mark)) {
      this.markerClusterer && this.markerClusterer.clearMarkers()
      this.createCluster()
    }
  }
  componentWillUnmount() {
    // 清除所有的监听器
    this.map && QMap.event.clearListeners(this.map)
  }
  _addScript = () => {   
    const { apiKey } = this.props  
    const getScript = (src) => {
      const protocol = (window.location.protocol === "https:") ? "https://" : "http://"
      src = protocol + src
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = src
      document.body.appendChild(script)
      script.onload = () => {   // js加载以后
        // console.log(QMap)
        QMap = window.qq.maps
        checkCenter(this.props.center) && this._initMap(this.props)
      }
    }
    qq.maps.__load = function (apiLoad) {
      delete qq.maps.__load
      apiLoad([["2.4.94",apiKey,0],["3gimg.qq.com/","lightmap/api_v2/2/4/94/mods/","3gimg.qq.com/lightmap/api_v2/2/4/94/theme/",true],[1,18,34.519469,104.461761,4],[1531469613137,"pr.map.qq.com/pingd","pr.map.qq.com/pingd"],["apis.map.qq.com/jsapi","apikey.map.qq.com/mkey/index.php/mkey/check","sv.map.qq.com/xf","sv.map.qq.com/boundinfo","sv.map.qq.com/rarp","apis.map.qq.com/api/proxy/search","apis.map.qq.com/api/proxy/routes/","confinfo.map.qq.com/confinfo","overseactrl.map.qq.com"],[[null,["rt0.map.gtimg.com/tile","rt1.map.gtimg.com/tile","rt2.map.gtimg.com/tile","rt3.map.gtimg.com/tile"],"png",[256,256],3,19,"114",true,false],[null,["m0.map.gtimg.com/hwap","m1.map.gtimg.com/hwap","m2.map.gtimg.com/hwap","m3.map.gtimg.com/hwap"],"png",[128,128],3,18,"110",false,false],[null,["p0.map.gtimg.com/sateTiles","p1.map.gtimg.com/sateTiles","p2.map.gtimg.com/sateTiles","p3.map.gtimg.com/sateTiles"],"jpg",[256,256],1,19,"101",false,false],[null,["rt0.map.gtimg.com/tile","rt1.map.gtimg.com/tile","rt2.map.gtimg.com/tile","rt3.map.gtimg.com/tile"],"png",[256,256],1,19,"",false,false],[null,["sv0.map.qq.com/hlrender/","sv1.map.qq.com/hlrender/","sv2.map.qq.com/hlrender/","sv3.map.qq.com/hlrender/"],"png",[256,256],1,19,"",false,false],[null,["rtt2.map.qq.com/rtt/","rtt2a.map.qq.com/rtt/","rtt2b.map.qq.com/rtt/","rtt2c.map.qq.com/rtt/"],"png",[256,256],1,19,"",false,false],null,[["rt0.map.gtimg.com/vector/","rt1.map.gtimg.com/vector/","rt2.map.gtimg.com/vector/","rt3.map.gtimg.com/vector/"],[256,256],3,18,"114",["rt0.map.gtimg.com/icons/","rt1.map.gtimg.com/icons/","rt2.map.gtimg.com/icons/","rt3.map.gtimg.com/icons/"]],null],["s.map.qq.com/TPano/v1.1.2/TPano.js","map.qq.com/",""]],loadScriptTime)
      }
      var loadScriptTime = (new Date).getTime()
      getScript("3gimg.qq.com/lightmap/api_v2/2/4/94/main.js")
  }
  createCluster() {
    const {mark} = this.props
    let markers = new qq.maps.MVCArray()

    mark.forEach(i => {
      var latLng = new QMap.LatLng(i.latitude, i.longitude)
      const content = `<div style="font-size:10px;color:#fff;">${i.title}</div>`
      var decoration = new QMap.MarkerDecoration(content, new QMap.Point(0, -10))
      var marker = new QMap.Marker({
        map: this.map,
        position: latLng,
        animation: QMap.MarkerAnimation.DROP,
        icon: new QMap.MarkerImage(icon_location),
        decoration: decoration
      })
      markers.push(marker)
    })

    this.setState({markers})

    this.markerClusterer = new qq.maps.MarkerCluster({
      map: this.map,
      minimumClusterSize: 10,
      markers: markers,
      zoomOnClick: true, //默认为true
      gridSize: 30, //默认60
      averageCenter: true, //默认false
      maxZoom: 18, //默认18
    }) 
  }

  _initMap = (props) => {
    const { center, initialOptions, getContainer } = props
    INITIALOPTIONS.mapTypeId = QMap.MapTypeId.HYBRID
    const options = Object.assign({}, INITIALOPTIONS, initialOptions)
    this.map = new QMap.Map(this.container, {
      center: new QMap.LatLng(center.latitude, center.longitude),
      ...options,
    })
    this.createCluster()
    getContainer && getContainer(this.container)
  }
  
  render() {
    const { className, style } = this.props

    return (
      <div 
        ref={div => this.container = div} 
        style={{width: '100%', height: '100%', ...style}}
        {...{className}}
      />
    )
  }
}

ReactQMap.propTypes = {
  initialOptions: PropTypes.object,
  getMap: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  mySpot: PropTypes.object,
  apiKey: PropTypes.string.isRequired,
  center: PropTypes.object.isRequired,
  getContainer: PropTypes.func,
}

export default ReactQMap