import React from 'react'
import {utils} from 'dtchain-fe'
import Card from '../../../components/Card'
import Checkout from '../../../components/Checkout'
import DebtInfoIcon from '../../../styles/images/chain/debt-info.png'

const DebtInfo = ({hash, data={}}) => {

  const content=[
    {label: '核心企业(债务人)', content: data['debtor_name'] || '--'},
    {label: '债权到期时间', content: utils.formatTime(data['expire_time'])},
    {label: '资产池总价值', content: utils.formatCurrency(data['pool_credit'])},
    {label: '资产数量', content: <span>{`共${data['asset_count'] || '--'}条记录`}<Checkout title='点击查看资产列表' url={`/detail/assets/${hash}`}/></span>},
  ]

  return (
    <Card title='债务信息' icon={DebtInfoIcon} content={content}/>
  )
}

export default DebtInfo