import React from 'react'
import { Table, Badge } from 'antd'
import Card from '../../components/Card'
import nodeIcon from '../../styles/images/home/node-info.png'

const columns = [
  {
    title: <span className='column-title'>节点名称</span>,
    dataIndex: 'node_name',
    key: 'node_name',
    align: 'center',
    className: 'table-column',
  },
  {
    title: <span className='column-title'>位置</span>,
    dataIndex: 'location_city',
    key: 'location_city',
    className: 'table-column',
    align: 'center',
    render: (text, record) => `${text}(${record.node_ip})`
  },
  {
    title: <span className='column-title'>节点ID</span>,
    dataIndex: 'node_id',
    key: 'node_id',
    align: 'center',
    className: 'table-column',
  },
  {
    title: <span className='column-title'>状态</span>,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    className: 'table-column',
    render: text => text ? <Badge status={
      text.code === 1
      ? 'default'
        : text.code === 2
      ? 'warning' : 'success'
    } text={text.value}/> : '--'
  },
]

const NodeTable = ({data, loading}) => (
  <Card title='节点信息' icon={nodeIcon} className='home-node'>
    <div className='table-container'>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered={false}
        rowKey='node_id'
      />
    </div>
  </Card>
)

export default NodeTable