import React, {useState} from 'react'
import {Input, Button} from 'antd'
import {useHistory} from 'react-router-dom'
import * as lodash from 'lodash'
import api, {handleResponse} from '../api'
import {utils} from 'dtchain-fe'

const Mask = ({visible=false, compare = ''}) => {
  const history = useHistory()
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const onSearch = async () => {
    if(!value) {
      return
    }

    const reg = /([A-Za-z0-9]+)(?=\*+)/
    const str = compare.match(reg)[0]
    if(!value.includes(str)) {
      utils.Message('warning', '所输入哈希不匹配')
      return
    }

    try {
      setLoading(true)
      const response = await api.getDetail(value)
      handleResponse({
        response: response,
        resolve: data => {
          const resData = data[0]
          const type = lodash.get(resData, 'trx_info.biz_type', {})
          const code = type.code
          setLoading(false)
          if(code) {
            const route = `/detail/${value}`
            history.replace(route, {data: resData})
          }
          else {
            utils.Message('error', '所输入哈希不正确')
          }
        },
        reject: () => setLoading(false)
      })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className='mask' style={{display: visible ? 'flex' : 'none'}}>
      <div className='mask-overlay' />
      <div className='mask-content'>
        <div className='mask-content-title'>输入完整哈希值以查看更多信息</div>
        <div className='mask-content-hash'>
          <Input
            style={{textAlign: 'center'}}
            onChange={e => setValue(e.target.value)}
            onPressEnter={onSearch}
          />
        </div>
        <Button type='primary' shape='round' onClick={onSearch} loading={loading}>确定</Button>
      </div>
    </div>
  )
}

export default Mask