import React from 'react'
import {utils} from 'dtchain-fe'
import * as lodash from 'lodash'
import Card from '../../../components/Card'
import Checkout from '../../../components/Checkout'
import TokenInfoIcon from '../../../styles/images/chain/token-info.png'

const TokenInfo = ({hash, data={}}) => {

  const content=[
    {label: '凭证编号', content: data['token_no']},
    {label: '凭证总额', content: utils.formatCurrency(data['credit'])},
    {label: '持有方', content: data['corp_name']},
    {label: '当前状态', content: lodash.get(data, 'status.value', '--')},
    {label: '生成时间', content: utils.formatTime(data['created'])},
    {label: '溯源记录', content: <span>{`共${lodash.get(data, 'trace_level', '-')}条记录`} <Checkout title='点击查看详细记录' url={`/detail/origin/${hash}`}/></span>},
  ]

  return (
    <Card title='凭证信息' icon={TokenInfoIcon} content={content}/>
  )
}

export default TokenInfo