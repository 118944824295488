import React from 'react'
import {useParams} from 'react-router-dom'
import * as lodash from 'lodash'
import IntegralCard from '../../components/IntegralCard'
import TokenInfo from './components/TokenInfo'
import DebtInfo from './components/DebtInfo'
import TransRecords from './components/TransRecords'
import SettleRecords from './components/SettleRecords'

const Chain = ({data={}}) => {
  const params = useParams()
  return (
    <div className='chain'>
      <IntegralCard data={lodash.get(data, 'trx_info', {})}/>
      <TokenInfo data={lodash.get(data, 'reference_data.info', {})} hash={params['id']}/>
      <DebtInfo data={lodash.get(data, 'reference_data.debtor', {})} hash={params['id']} />
      <TransRecords data={lodash.get(data, 'reference_data.transfers', [])} hash={params['id']} />
      <SettleRecords data={lodash.get(data, 'reference_data.monetizes', [])} hash={params['id']}/>
    </div>
  )
}

export default Chain