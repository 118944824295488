import React, {useEffect, useState} from 'react'
import {utils} from 'dtchain-fe'
import {Table} from 'antd'
import {useParams} from 'react-router-dom'
import Breadcrumb from '../../components/Breadcrumb'
import api, {handleResponse} from '../../api'

const getColor = index => index === 0 ? '#3399FF' : ''

const columns = [
  {
    title: '序号',
    align: 'center',
    dataIndex: 'level',
    key: 'level',
    render: (text, record, index) => <span style={{color: getColor(index)}}>{text || '--'}</span>
  }, {
    title: '凭证编号',
    align: 'center',
    dataIndex: 'token_no',
    key: 'token_no',
    render: (text, record, index) => <span style={{color: getColor(index)}}>{index === 0 ? `${text}(当前)` : text}</span>
  }, {
    title: '持有企业',
    align: 'center',
    dataIndex: 'corp_name',
    key: 'corp_name',
    render: (text, record, index) =><span style={{color: getColor(index)}}>{text || '--'}</span>
  }, {
    title: '生成时间',
    align: 'center',
    dataIndex: 'trx_time',
    key: 'trx_time',
    render: (text, record, index) => <span style={{color: getColor(index)}}>{utils.formatTime(text)}</span>
  }
]

const showPagination = (total, size=15) => {
  if(total <= size) return false
  return {
    pageSize: size
  }
}

const OriginRecords = () => {
  const params = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const breadcrumb = [
    {breadcrumbName: '首页', path: '/'},
    {breadcrumbName: '交易详情', path: `/detail/${params['hash']}`},
    {breadcrumbName: '溯源记录'},
  ]

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const res = await api.getOriginRecords(params['hash'])
        handleResponse({
          response: res,
          resolve: tableData => {
            setData(tableData)
            setLoading(false)
          },
          reject: () => setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    })()
  }, [params])
  
  return (
    <div className='chain-origin container'>
      <Breadcrumb routes={breadcrumb} />
      <div className='chain-origin-table'>
        <Table 
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={showPagination(data.length)}
          rowKey='level'
        />
      </div>
    </div>
  )
}


export default OriginRecords