import React from 'react'
import {Popover} from 'antd'
import {utils} from 'dtchain-fe'

const textMap = {
  '-1': '无需锚定',
  '1': '区块待确认',
  '0': '交易待锚定',
  '16': '区块已确认'
}

const CheckContent = ({content = {}}) => {
  const {anchor_time, trx_hash, decrypt_password, trace_url, encrypt_key, verify_url, status = {}} = content
  const confirmed = status.code === 1 || status.code === 16 || status.code === 0
  return (
    <div className='check-btn-content'>
      <div className='check-btn-content-item'>数据已加密并用于公有链网络交叉验证。</div>
      <div className='check-btn-content-item'>
        {anchor_time && `${utils.formatTime(anchor_time)} `}
        <span className={confirmed ? 'check-btn-content-confirmed' : ''}>
          {textMap[status.code]}
        </span>
      </div>
      {
        confirmed && (
          <div className='check-btn-content-extra check-btn-content-item'>
            <div className='check-btn-content-extra-item'>
              <div className='check-btn-content-extra-item-label'>公链交易哈希：</div>
              <div className='check-btn-content-extra-item-value'>
                {trx_hash || '--'}
                <a className='check-btn-content-extra-item-btn' href={trace_url || verify_url} target="_blank" rel="noopener noreferrer">查询</a>
              </div>
            </div>
            <div className='check-btn-content-extra-item'>
              <div className='check-btn-content-extra-item-label'>解密密码：</div>
              <div className='check-btn-content-extra-item-value'>{decrypt_password || encrypt_key}</div>
            </div>
          </div>
        )
      }
    </div>
  )
}

const CheckButton = ({anchorInfo={}}) => {

  return (
    <Popover
      content={<CheckContent content={anchorInfo}/>}
      trigger='click'
    >
      <span className='check-btn-text'>验</span>
    </Popover>
  )
}

export default CheckButton