import {fetchData} from "./resource"

export default {
  getIndexData: () => {
    return fetchData(`get`, `/index`)
  },
  getDetail: (id) => {
    return fetchData(`get`, `/trx/${id}`)
  },
  getSettleRecords: function(params) {
    return fetchData(`get`, `/supply-chain/flow/${params.hash}/monetize/${params.requestNo}`)
  },
  getTransRecords: function(params) {
    return fetchData(`get`, `/supply-chain/flow/${params.hash}/contract/${params.contractNo}`)
  },
  getAssetsRecords: function(params) {
    return fetchData(`get`, `/supply-chain/flow/${params.hash}/assets`, {params})
  },
  getOriginRecords: function(hash) {
    return fetchData(`get`, `/supply-chain/flow/${hash}/trace`)
  }
}

export const handleResponse = ({response, resolve=()=>{}, reject=()=>{}}) => {
  if(response.data.code === 0) {
    resolve(response.data.data)
  }
  else {
    reject()
  }
}
