import React from 'react'
import {Popover, Badge} from 'antd'
import './nodeAnimation.less'

const NodeAnimation = ({top = 0, left = 0, data}) => {
  const style = {
    top, left
  }
  const status = data ? data.status : {}
  const content = data ? (
    <div style={{color: '#202020'}}>
      <p>{data.location_city}({data.node_ip})</p>
      <p><Badge status={
        status.code === 1
          ? 'default'
          : status.code === 2
          ? 'warning' : 'success'
      } text={status.value} style={{color: "#202020"}}/></p>
    </div>
  ) : '数据获取中……'
  return (
    <div className="water-container">
      <Popover content={content} placement="bottomRight">
        <div className="water" style={style}>
          <div className="point point1"/>
          <div className="point point2"/>
          <div className="point point3"/>
          <div className="point point4"/>
        </div>
      </Popover>
    </div>
  )
}

export default NodeAnimation