import React from 'react'
import {utils} from 'dtchain-fe'
import Card from './Card'
import SettleIcon from '../styles/images/chain/settler.png'

const SettleInfo = ({data={}}) => {

  const content=[
    {label: '凭证编号', content: data['token_no'] || '-'},
    {label: '支付面值', content: utils.formatCurrency(data['money'])},
    {label: '结算金额', content: utils.formatCurrency(data['credit'])},
    {label: '结算方', content: data['corp_name'] || '-'},
    {label: '债务人', content: data['debtor_name'] || '-'},
    {label: '转出地址', content: data['from_address'] || '--'},
  ]

  return (
    <Card title='结算方信息' icon={SettleIcon} content={content}/>
  )
}

export default SettleInfo