import React from 'react'
import {Table} from 'antd'
import {Link} from 'react-router-dom'
import {utils} from 'dtchain-fe'
import Card from '../../../components/Card'
import TransIcon from '../../../styles/images/chain/trans-record.png'

const columns = (hash) => [
  {
    title: <span className='column-title'>合同编号</span>,
    dataIndex: 'contract_no',
    key: 'contract_no',
    align: 'center',
    className: 'table-column',
    render: text => (<Link to={`/detail/trans/${hash}/${text}`}>{text}</Link>)
  },
  {
    title: <span className='column-title'>转出面值</span>,
    dataIndex: 'credit',
    key: 'credit',
    className: 'table-column',
    align: 'center',
    render: text => utils.formatCurrency(text)
  },
  {
    title: <span className='column-title'>转入凭证</span>,
    dataIndex: 'token_no',
    key: 'token_no',
    align: 'center',
    className: 'table-column',
  },
  {
    title: <span className='column-title'>持有方</span>,
    dataIndex: 'corp_name',
    key: 'corp_name',
    align: 'center',
    className: 'table-column',
  },
  {
    title: <span className='column-title'>转入面值</span>,
    dataIndex: 'money',
    key: 'money',
    align: 'center',
    className: 'table-column',
    render: text => utils.formatCurrency(text)
  },
  {
    title: <span className='column-title'>转入时间</span>,
    dataIndex: 'trx_time',
    key: 'trx_time',
    align: 'center',
    className: 'table-column',
    render: text => utils.formatTime(text)
  },
]

const TransRecords = ({data=[], hash}) => {

  return (
    <Card title='转出记录' icon={TransIcon} className='trans-records'>
      <div className='table-container'>
        <Table 
          columns={columns(hash)} 
          dataSource={data} 
          pagination={false}
          bordered={false}
          rowKey='contract_no'
          scroll={ data.length > 5 ? { y: 200 } : {}} 
        />
      </div>
    </Card>
  )
}

export default TransRecords