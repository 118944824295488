import React from 'react'
import IntegralCard from '../../components/IntegralCard'

const Integral = ({data={}}) => {
  return (
    <div className='integral'>
      <IntegralCard data={data['trx_info']}/>
    </div>
  )
}

export default Integral