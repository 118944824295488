import React from 'react'
import Card from './Card'
import icon from '../styles/images/trace/shangpin.png'

const ProductInfo = ({data = {}}) => {

  const location = data.location || {}

  const content=[
    {label: '商品名称', content: data.name},
    {label: '企业名', content: data.owner},
    {label: '记录坐标', content: `${location.lng || '--'}, ${location.lat || '--'}`},
    {label: '映物码', content: data.code},
    {label: '商品简介', content: data.description},
  ]

  return (
    <Card icon={icon} title='商品信息' content={content}/>
  )
}

export default ProductInfo