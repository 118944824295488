import React from 'react'
import {Table} from 'antd'
import {Link} from 'react-router-dom'
import {utils} from 'dtchain-fe'
import Card from '../../components/Card'
import tradeIcon from '../../styles/images/home/trade.png'

const formatHash = (hash) => {
 const reg = /(\*)+/
 return hash.replace(reg, '****')
}

const columns = [
  {
    title: <span className='column-title'>类型</span>,
    dataIndex: 'trx_type',
    key: 'trx_type',
    align: 'center',
    width: 100,
    className: 'table-column',
    render: text => text ? text.value : '-'
  },
  {
    title: <span className='column-title'>交易哈希</span>,
    dataIndex: 'trx_hash',
    key: 'trx_hash',
    width: 300,
    className: 'table-column word-break',
    align: 'center',
    render: (text, record) => {
      return <Link to={{
        pathname: '/detail',
        state: {data: {trx_info: record}}
      }}>{formatHash(text)}</Link>
    }
  },
  {
    title: <span className='column-title'>交易量</span>,
    dataIndex: 'credit',
    key: 'credit',
    align: 'center',
    className: 'table-column'
  },
  {
    title: <span className='column-title'>发送方</span>,
    dataIndex: 'from_address',
    key: 'from_address',
    align: 'center',
    className: 'table-column',
    render: text => formatHash(text)
  },
  {
    title: <span className='column-title'>接收方</span>,
    dataIndex: 'to_address',
    key: 'to_address',
    align: 'center',
    className: 'table-column',
    render: text => formatHash(text)
  },
  {
    title: <span className='column-title'>时间</span>,
    dataIndex: 'trx_time',
    key: 'trx_time',
    align: 'center',
    width: 200,
    className: 'table-column',
    render: text => utils.formatTime(text)
  },
]

const TradeTable = ({data, loading}) => (
  <Card title='最新交易' icon={tradeIcon} className='home-trade'>
    <div className='table-container'>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered={false}
        rowKey="trx_hash"
        scroll={ data.length > 6 ? { y: 240 } : {} }
      />
    </div>
  </Card>
)

export default TradeTable