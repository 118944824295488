import React, {useState, useEffect} from 'react'
import {Spin} from 'antd'
import {useHistory, useLocation} from 'react-router-dom'
import search from '../styles/images/search.png'
import api, {handleResponse} from '../api'
import {get} from 'lodash'
import {utils} from 'dtchain-fe'

const Search = () => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue('')
  },[location.pathname])

  const onSearch = async () => {
    if(value) {
      try {
        setLoading(true)
        const response = await api.getDetail(value.trim())
        handleResponse({
          response,
          resolve: resData => {
            setLoading(false)
            if(resData.length === 0) {
              utils.Message('warning', '交易未找到')
            } else {
              const type = get(resData[0], 'trx_info.biz_type', {})
              const code = type.code
              if(code) {
                const route = `/detail/${value}`
                history.push(route, {data: resData[0]})
              }
            }
          },
          reject: () => setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    }
  }

  return (
    <div className="search-input">
      <Spin spinning={loading} wrapperClassName="search-icon">
        <img src={search} alt="search" onClick={onSearch}/>
      </Spin>
      <input
        placeholder="输入交易哈希查询相关信息"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyUp={e => e.keyCode === 13 && onSearch()}
      />
    </div>
  )
}

export default Search