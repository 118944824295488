import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App'
import NotFound from "./pages/not_found"

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props =><Component {...props} />}/>
  )
}

export default () => (
  <Router>
    <Switch>
      <PrivateRoute component={App} />
      <Route component={NotFound}/>
    </Switch>
  </Router>
)
