import React from 'react'
import {Alert} from 'antd'

const styles = {
  width: 84,

}
/**
 * 
 * @param {number} status 0 = 流通中， 1 = 成功
 */
const TransStatus = ({status=1}) => {
  return (
    status ? 
      <Alert type="success" message='成功' showIcon style={styles}/> :
      <Alert type="info" message='流通中' showIcon style={styles}/>
  )
}

export default TransStatus