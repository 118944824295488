import React from 'react'
import logo from '../styles/images/logo.png'
import Search from "./Search"

const Header = ({search = true, ...props}) =>  (
  <div className="topBar" style={search ? {borderBottom: '1px solid #CBCBCB'} : {}}>
    <img className="logo" src={logo} alt="logo"/>
    {search ? <Search {...props}/> : null}
  </div>
)

export default Header