import * as types from "../constants/ActionTypes"
import api from "../api"

export const getAssetsRecords = (actionData={page: 1, searchData: {}, loadType: false}) => {
  const params = {
    page_no: actionData.page || 1,
    page_size: 15
  }
  actionData.searchData['hash'] && (params['hash'] = actionData.searchData['hash'])
  return {
    type: types.GET_ASSETS_RECORDS,
    promise: api.getAssetsRecords(params),
    loadType: actionData.loadType
  }
}