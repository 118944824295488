import React from 'react'
import Card from '../../components/Card'
import blockIcon from '../../styles/images/home/block.png'

const BlockInfo = ({node, block_height, confirmed_height}) => (
  <Card title='区块信息' icon={blockIcon} className='home-block'>
    <div className="content">
      <div className="item">
        <div className="num">{node || 0}</div>
        <div className="block-des">当前节点数</div>
      </div>
      <div className="item">
        <div className="num">{block_height || 0}</div>
        <div className="block-des">最新区块</div>
      </div>
      <div className="item">
        <div className="num">{confirmed_height || 0}</div>
        <div className="block-des">最新不可逆区块</div>
      </div>
    </div>
  </Card>
)

export default BlockInfo