import React from 'react'
import {Route, Switch} from 'react-router-dom'
import SettleRecords from './settle_records'
import TransInfo from './trans_info'
import OriginRecords from './origin_records'
import AssetsRecords from './assets_records'
import NotFound from '../not_found'

export default () => {
  return (
    <Switch>
      <Route exact path='/detail/settle/:hash/:requestNo' component={SettleRecords} />
      <Route exact path='/detail/trans/:hash/:contractNo' component={TransInfo} />
      <Route exact path='/detail/origin/:hash' component={OriginRecords} />
      <Route exact path='/detail/assets/:hash' component={AssetsRecords} />
      <Route component={NotFound}/>
    </Switch>
  )
}
