import React from 'react'

const Footer = () => (
  <div className="footer">
    <a href="http://www.beian.miit.gov.cn/" target="blank">
      © 2020 蓝海数链 | 桂网信备45020219116193390010号 | 桂ICP备18003024号-4
    </a>
  </div>
)

export default Footer