import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Spin} from 'antd'
import Breadcrumb from '../../components/Breadcrumb'
import SettleInfo from '../../components/SettleInfo'
import SettleDetail from '../../components/SettleDetail'
import api, {handleResponse} from '../../api'

const SettleRecords = () => {
  const params = useParams()
  const [settleData, setSettleData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const response = await api.getSettleRecords({hash: params['hash'], requestNo: params['requestNo']})
        handleResponse({
          response,
          resolve: data => {
            setSettleData(data)
            setLoading(false)
          },
          reject: () => setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    })()
  }, [params])

  const breadcrumb = [
    {breadcrumbName: '首页', path: '/'},
    {breadcrumbName: '交易详情', path: `/detail/${params['hash']}`},
    {breadcrumbName: '结算信息'},
  ]

  return (
    <div className='chain-settle container'>
      <Breadcrumb routes={breadcrumb} />
      <Spin spinning={loading}>
        <SettleInfo data={settleData['data']}/>
        <SettleDetail data={settleData['data']} anchorInfo={settleData['anchor_info']}/>
      </Spin>
    </div>
  )
}

export default SettleRecords