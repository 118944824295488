import React from 'react'
import * as lodash from 'lodash'
import CheckButton from './CheckButton'

const BlockAddress = ({anchorInfo, hash='-'}) => (
  <div className='block-address'>
    <span className='block-address-hash'>{hash}</span>
    {anchorInfo && lodash.get(anchorInfo, 'status.code')  !== -1 && <span className='block-address-button'><CheckButton anchorInfo={anchorInfo}/></span>}
  </div>
)

export default BlockAddress