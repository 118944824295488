import React, {useState} from 'react'
import {Table, Gallery, utils} from 'dtchain-fe'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {useParams, useLocation} from 'react-router-dom'
import {Button, Modal} from 'antd'
import * as lodash from 'lodash'
import Breadcrumb from '../../components/Breadcrumb'
import * as Actions from '../../actions/assets'

const columns = (changeVisible, page) => [
  {
    title: '序号',
    align: 'center',
    key: 'no',
    render: (text, data, index) => (page - 1) * 15 + index + 1
  }, {
    title: '资产编号',
    align: 'center',
    dataIndex: 'asset_no',
    key: 'asset_no',
    render: text => text || '--'
  }, {
    title: '资产名称',
    align: 'center',
    dataIndex: 'name',
    key: 'name',
    render: text => text || '--'
  }, {
    title: '资产类型',
    align: 'center',
    dataIndex: 'type',
    key: 'type',
    render: text => lodash.get(text, 'value', '--')
  }, {
    title: '抵押额度',
    align: 'center',
    dataIndex: 'credit',
    key: 'credit',
    render: text => utils.formatCurrency(text)
  }, {
    title: '附件',
    align: 'center',
    dataIndex: 'attachments',
    key: 'attachments',
    render: (text, records) => {
      return (
        <Button size="small" shape="round" disabled={text.length === 0} onClick={() => changeVisible(records['attachments'])}>附件</Button>
      )
    }
  }
]

const showPagination = (PaginInfo={total_records:0, page_size:15}, size=15) => {
  if(PaginInfo.total_records <= size) return false
  return PaginInfo
}

const AssetsRecords = ({...props}) => {
  const params = useParams()
  const location = useLocation()
  const {actions, assetsRecords} = props
  const {total_records, page_size, isFetching} = assetsRecords
  const [visible, setVisible] = useState(false)
  const [images, setImages] = useState([])

  const breadcrumb = [
    {breadcrumbName: '首页', path: '/'},
    {breadcrumbName: '交易详情', path: `/detail/${params['hash']}`},
    {breadcrumbName: '资产列表'},
  ]

  const handleModal = attachments => {
    setVisible(true)
    setImages(attachments.map(item => ({
      source: item['file_url']
    })))
  }

  return (
    <div className='chain-assets container'>
      <Breadcrumb routes={breadcrumb} />
      <div className='chain-assets-table'>
        <Table 
          columns={columns(attachments => handleModal(attachments), lodash.get(location, 'state.page', 1))}
          data={assetsRecords}
          loading={isFetching}
          actions={actions['getAssetsRecords']}
          pageInfo={showPagination({total_records, page_size})}
          hasShadow={false}
          extraParams={{hash: params['hash']}}
        />
      </div>
      <Modal
        visible={visible}
        footer={null}
        title='手续合同'
        onCancel={() => setVisible(false)}
        className='chain-assets-modal'
      >
        <Gallery images={images}/>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    assetsRecords: state.chain.assetsRecords
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetsRecords)