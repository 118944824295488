import React from 'react'
import {Link} from 'react-router-dom'
import {Breadcrumb as A_Breadcrumb} from 'antd'

const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  )
}

/**
 * 
 * @param {Array} routes [{path: '', breadcrumbName: ''}] 
 */

const Breadcrumb = ({routes, separator='>'}) => (
  <A_Breadcrumb style={{margin: '20px 0'}} itemRender={itemRender} routes={routes} separator={separator}/>
)

export default Breadcrumb