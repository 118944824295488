import React from 'react'
import {utils} from 'dtchain-fe'
import Card from './Card'
import BlockAddress from './BlockAddress'
import SettleIcon from '../styles/images/chain/settle-detail.png'

const SettleDetail = ({ anchorInfo, data={}}) => {

  const content=[
    {label: '交易创建时间', content: utils.formatTime(data['request_time'], {hasSecond: false, hasTime: false})},
    {label: '结算单号', content: data['request_no'] || '-'},
  ]
    
  anchorInfo && content.push(
    {label: '区块地址', content: <BlockAddress hash={data['block_hash']} anchorInfo={anchorInfo}/>}
    )

  return (
    <Card title='结算详情' icon={SettleIcon} content={content}/>
  )
}

export default SettleDetail