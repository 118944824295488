import React from 'react'
import {Row, Col} from 'antd'

const CardContent = ({label, content}) => {
  return (
    <Row className="card-content-item">
      <Col span={4}><span className="card-content-item-label">{label}</span></Col>
      <Col span={20}><span className="card-content-item-content">{content || '--'}</span></Col>
    </Row>
  )
}

const Card = ({icon, content, className="",title='', ...props}) => (
    <div className={`card ${className}`}>
      { title && <div className="card-title" style={{backgroundImage: `url(${icon})`}}>{title}</div> }
      <div className='card-content'>
        {
          content ? 
            content.map(item => <CardContent label={item['label']} key={item['label']} content={item['content']}/>) :
              props.children
        }
      </div>
    </div>
)


export default Card